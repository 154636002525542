
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { Validate, getValidatorMap } from '@/mixin/validator';
import storage from 'baimahu/storage';
import moment, { Moment } from 'moment';
import { addCoupon, getWareList, getCouponDetail, editCoupon } from '@/api/marketing/coupon';
import { queryWareCategory } from '@/api/ware/category';
import { date } from '@/util/adapter';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const MAX_LENGTH = 24;
const _ = {
  findIndex: require('lodash/findIndex'),
};

function initForm(remote?: any) {
  remote = remote || {};
  return {
    type: remote.type || 1, //类型，1满减券，2折扣券
    name: remote.name || '', //名称
    grant_type: remote.grant_type || 0, //发放类型 1：手动领取 2：券码兑换 3:系统发放 4:分享领取'
    user_type: remote.user_type, //用户类型：1全部用户，2新用户，3老用户
    threshold: remote.threshold || 2, //是否有订单金额门槛，1是，2否
    threshold_money: remote.threshold_money / 100 || 0, //订单金额门槛，单位：分
    money: remote.type == 1 ? remote.money / 100 : remote.discount, //无门槛减免金额，单位：分
    limit_money: remote.type == 1 ? remote.money / 100 : remote.discount, //有门槛减免金额，单位：分
    discount: remote.discount || 0, //折扣
    limit_discount: remote.limit_discount || '', // 有门槛折扣
    concessions_limit: remote.concessions_limit || 2, //优惠金额上限，1是，2否
    concessions_limit_money: remote.concessions_limit_money / 100 || '', //优惠金额上限金额，单位：分
    range: remote.range || 1, //范围：1通用, 2商品, 3品类
    sub_range: remote.sub_range || 1, //子范围：1部分可用，2部分不可用
    product_id: remote.product_id || ([] as any[]), //商品id（黑名单与白名单商品都传此字段）
    category_id: remote.category_id || ([] as any[]), //品类id（黑名单与白名单商品都传此字段）
    expire_type: remote.expire_type || 1, //有效期类型：1开始结束时间，2领券当日起天数，3领券次日起天数
    start_time: remote.start_time || 0, //有效期开始时间
    end_time: remote.end_time || 0, //有效期结束时间
    start_time_obj: remote.start_time_obj || null,
    end_time_obj: remote.end_time_obj || null,
    expire_days: remote.expire_days || 0, //有效期天数
    description: remote.description || '', //描述
    //stock: remote.stock || '', //库存
    circulation_limit: remote.circulation_limit || 1, //是否限量发行, 1是2否
    circulation_number: remote.circulation_number, //计划发放总量
    circulated_number: remote.circulated_number, //已发放总量
    personal_limit: remote.personal_limit || 2, //个人限领，1不限制，2限制
    personal_limit_number: remote.personal_limit_number || 1, //个人限领次数
    concessions_overlay: remote.concessions_overlay || 1, //优惠叠加：1不可叠加，2可叠加
    shown_place: remote?.shown_place || [], //显示位置，数组：1商详页
    received_number: remote?.received_number || 0,
    //ffCountCheck
    product_data: remote.product_data || {
      data: [] as any[],
    },
    class_data: remote?.class_data || {
      data: [] as any[],
    },
    id: remote.id || '',
    points_exchange: remote.points_exchange || false,
    points: remote.points || 0,
    expiration_remind: remote.expiration_remind == 1 ? true : false,
    expiration_remind_days: remote.expiration_remind_days || 1,
  };
}
function getValidatorList() {
  return {
    name: {
      status: '',
      help: '',
      tip_text: '请输入姓名',
    },
    money: {
      status: '',
      help: '',
      tip_text: '请输入金额',
    },
    stock: {
      status: '',
      help: '',
      tip_text: '请输入剩余可发放量',
    },
    personal_limit_number: {
      status: '',
      help: '',
      tip_text: '请输入限领次数',
    },
    time: {
      status: '',
      help: '',
      tip_text: '请填写有效期,如果是时间选择，结束时间大于开始时间，若是天数，请输入正整数 ',
    },
    product_data: {
      status: '',
      help: '',
      tip_text: '请填写有效期 ',
    },
    class_data: {
      status: '',
      help: '',
      tip_text: '请填写有效期 ',
    },
    concessions_limit_money: {
      status: '',
      help: '',
    },
    shown_place: {
      status: '',
      help: '',
      tip_text: '请至少选择一项',
    },
    date_chock: {
      status: '',
      help: '',
      tip_text: '结束时间不能大于开始时间',
    },
  };
}
const COUPON_TYPE = {
  1: {
    name: '满减券',
  },
  2: {
    name: '折扣券',
  },
};
const RANGE_TYPE = {
  1: {
    name: '通用券',
  },
  2: {
    name: '商品券',
  },
  3: {
    name: '品类券',
  },
};

const WARE_STATUS = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '上架中',
  },
  {
    value: 2,
    label: '已下架',
  },
];
function initWareList(remote?: any) {
  remote = remote || {};
  return {
    detail: remote.detail || [],
    current: +remote.current || 0,
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  };
}
@Component
export default class AddCouponSecond extends Vue {
  globalFunction = useGlobalFunction().globalFunction;
  moment: any = moment;
  is_loading: boolean = false;
  form_item_layout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  is_show_category_modal: boolean = false;
  is_show_product_modal: boolean = false;
  get is_edit() {
    return !!this.$route.query.id;
  }
  category: any = [];
  sub_category: any = [];
  select_category: any = [];
  select_sub_category: any = [];
  form: any = initForm();
  validateMap: any = getValidatorList();
  page: number = 1;
  ware_list: any = initWareList();
  selectedRowKeys: any = [];
  modal_form_value: any = {
    id: '',
    name: '',
    status: 0,
    category: [],
  };
  points_exchange: boolean = false;
  points: number = 0;
  // onSelectPoints(e) {
  //   this.form.points_exchange = Number(e.target.checked);
  // }
  get couponType() {
    return COUPON_TYPE;
  }
  get rangeType() {
    return RANGE_TYPE;
  }
  get ISSUE_TYPE() {
    return this.form.grant_type == 2
      ? '券码兑换'
      : this.form.grant_type == 1
      ? '手动领取'
      : this.form.grant_type == 3
      ? '系统发放'
      : '分享领取';
  }
  get USER_TYPE() {
    return this.form.user_type == 2
      ? '新用户'
      : this.form.user_type == 1
      ? '全部用户'
      : this.form.user_type == 3
      ? '老用户'
      : 'APP会员';
  }

  get wareStatus() {
    return WARE_STATUS;
  }
  get maxLength() {
    return MAX_LENGTH;
  }
  @changeLoading(['is_loading'])
  async submit() {
    console.log(this.form);
    if (!this.validateForm()) {
      this.$message.error('请填写必填项并检查其值');
      return;
    }
    for (const prop in this.validateMap) {
      if (!this.validateInput(prop)) {
        this.$message.error('请填写合法值');
        return;
      }
    }
    let discount;
    if (this.form.type === 2 && this.form.threshold === 2) {
      discount = this.form.money;
    }
    if (this.form.type === 2 && this.form.threshold === 1) {
      discount = this.form.limit_money;
    }
    const form = this.form;
    console.log(this.form);
    const send_data: any = {
      name: form.name,
      type: form.type,
      range: form.range,
      description: form.description,
      //stock: this.is_edit ? form.stock - form.issued : form.stock,
      /* personal_limit: 2,
      personal_limit_number: form.personal_limit_number, */
      start_time: form.expire_type === 1 ? form.start_time_obj.format('X') : form.expire_days,
      end_time: form.expire_type === 1 ? form.end_time_obj.format('X') : form.expire_days,
      expire_type: form.expire_type,
      expire_days: form.expire_type !== 1 ? form.expire_days : '',
      category_id: form.range === 3 ? form.class_data.data.map((item: any) => item.id) : [],
      product_id: form.range === 2 ? form.product_data.data.map((item: any) => item.id) : [],
      threshold: form.threshold,
      threshold_money: form.threshold === 1 ? form.threshold_money * 100 : '',
      money: form.threshold === 1 ? form.limit_money * 100 : form.money * 100,
      concessions_limit: form.type === 2 ? form.concessions_limit : '',
      concessions_limit_money:
        form.type === 2
          ? form.concessions_limit === 1
            ? form.concessions_limit_money * 100
            : ''
          : '',
      shown_place: this.form.shown_place,
      discount: discount,
      concessions_overlay: form.concessions_overlay,
      grant_type: form.grant_type,
      user_type: form.user_type, //用户类型：1全部用户，2新用户，3老用户
      circulation_limit: form.circulation_limit, //是否限量发行, 1是2否
      circulation_number: form.circulation_number, //计划发放总量
      circulated_number: form.circulated_number, //已发放总量
      personal_limit: form.grant_type == 5 ? 1 : form.personal_limit, //个人限领，1不限制，2限制
      personal_limit_number: Number(form.personal_limit_number), //个人限领次数
      points_exchange: form.points_exchange == true ? 1 : 0,
      points: form.points || 0,
      expiration_remind: form.expiration_remind == true ? 1 : 2,
      expiration_remind_days: form.expiration_remind_days || undefined,
    };
    if (form.range !== 1) {
      send_data.sub_range = form.sub_range;
    }
    console.log(send_data);

    let res;

    if (this.is_edit) {
      res = await editCoupon(this.form.id, send_data);
    } else {
      res = await addCoupon(send_data);
    }
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success(this.is_edit ? '编辑成功' : '新增成功');
    // this.$router.push('/marketing/coupon');
    storage.removeItem('coupon_type');
    this.$router.push({
      path: '/marketing/coupon',
      query: { page: this.$route.query?.page || '1' },
    });
    this.globalFunction.getCouponList();
  }

  // 验证是否为纯数字
  validateNumber(number: string) {
    const reg = /^[1-9]\d*$/;
    return reg.test(number);
  }

  /**
   * @description 验证是否最多一位小数的数字
   * @param {string} number
   * @returns {number}
   */
  validateDoubleOne(number: string): boolean {
    if (Number(number) === 0) return false;
    return /^([0-9]\d*)(\.\d{1})?$/.test(number);
  }

  // 点击提交按钮时验证是否有必填项没有填
  validateForm() {
    let is_validate = true;
    if (this.form.name == '') {
      this.validateMap.name.help = '请填写名称';
      this.validateMap.name.status = 'error';
      is_validate = false;
      console.log('name');
    } else {
      this.validateMap.name.help = '';
      this.validateMap.name.status = '';
    }
    if (this.form.threshold === 1 && (!this.form.threshold_money || !this.form.limit_money)) {
      if (this.form.type === 1) {
        this.validateMap.money.help = '请填写金额';
        this.validateMap.money.status = 'error';
      } else {
        this.validateMap.money.help = '请填写满减金额与折扣';
        this.validateMap.money.status = 'error';
      }
      is_validate = false;
      console.log('money||discount');
    } else if (this.form.threshold === 2 && !this.form.money) {
      if (this.form.type === 1) {
        this.validateMap.money.help = '请填写金额';
        this.validateMap.money.status = 'error';
      } else {
        this.validateMap.money.help = '请填写折扣';
        this.validateMap.money.status = 'error';
      }
      is_validate = false;
    } else {
      this.validateMap.money.help = '';
      this.validateMap.money.status = '';
    }
    if (
      this.form.type === 2 &&
      this.form.concessions_limit === 1 &&
      !this.form.concessions_limit_money
    ) {
      this.validateMap.concessions_limit_money.help = '请填写最多优惠金额';
      this.validateMap.concessions_limit_money.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.concessions_limit_money.help = '';
      this.validateMap.concessions_limit_money.status = '';
    }
    if (
      this.form.expire_type === 1 &&
      (!this.form.start_time_obj ||
        !this.form.end_time_obj ||
        this.form.end_time_obj <= this.form.start_time_obj)
    ) {
      console.log('time');
      this.validateMap.time.help = '有效期为必填项,并且开始时间小于结束时间';
      this.validateMap.time.status = 'error';
      is_validate = false;
    } else if (this.form.expire_type !== 1 && !this.form.expire_days) {
      console.log('time1');
      this.validateMap.time.help = '请填写有效期';
      this.validateMap.time.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.time.help = '';
      this.validateMap.time.status = '';
    }
    if (this.form.range === 2 && this.form.product_data.data.length <= 0) {
      this.validateMap.product_data.help = '请选择商品';
      this.validateMap.product_data.status = 'error';
      is_validate = false;
      console.log('product');
    } else {
      this.validateMap.product_data.help = '';
      this.validateMap.product_data.status = '';
    }
    if (this.form.range === 3 && this.form.class_data.data.length <= 0) {
      this.validateMap.class_data.help = '请选择分类';
      this.validateMap.class_data.status = 'error';
      is_validate = false;
      console.log('class');
    } else {
      this.validateMap.class_data.help = '';
      this.validateMap.class_data.status = '';
    }

    if (!this.form.circulation_number && this.form.circulation_limit == 1) {
      this.validateMap.stock.help = '请填写剩余可发放量';
      this.validateMap.stock.status = 'error';
      is_validate = false;
      console.log('stock');
    } else {
      this.validateMap.stock.help = '';
      this.validateMap.stock.status = '';
    }
    if (this.form.personal_limit == 2 && !this.validateNumber(this.form.personal_limit_number)) {
      this.validateMap.personal_limit_number.help = '请输入每人限领次数';
      this.validateMap.personal_limit_number.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.personal_limit_number.help = '';
      this.validateMap.personal_limit_number.status = '';
    }
    if (
      (this.form.grant_type == 1 || this.form.grant_type == 4) &&
      this.form.shown_place.length <= 0
    ) {
      this.validateMap.shown_place.help = '请勾选显示位置';
      this.validateMap.shown_place.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.shown_place.help = '';
      this.validateMap.shown_place.status = '';
    }
    return is_validate;
  }

  // 表单输入时，验证是否有表单值不合法
  public validateInput(field: string) {
    let is_validate = true;
    const validateMoney = this.form.threshold === 2 && !this.validateDoubleOne(this.form.money);
    const validateMoney2 =
      this.form.threshold === 1 && !this.validateDoubleOne(this.form.limit_money);
    const validateMoney3 =
      this.form.threshold === 1 && !this.validateNumber(this.form.threshold_money);
    console.log(this.form.class_data.data);
    if (field === 'name') {
      if (this.form.name == '') {
        this.validateMap.name.help = '请填写名称';
        is_validate = false;
        this.validateMap.name.status = 'error';
      } else {
        this.validateMap.name.help = '';
        this.validateMap.name.status = '';
      }
    }
    if (field === 'money') {
      if (this.form.threshold === 1) {
        // 有门槛
        if (this.form.type == 1) {
          // 有门槛/满减券
          const no_pass =
            !this.validateNumber(this.form.threshold_money) ||
            !this.validateNumber(this.form.limit_money) ||
            this.form.threshold_money <= this.form.limit_money;
          if (no_pass) {
            this.validateMap.money.help = '满减金额请输入正整数，并且满减额不能大于订单金额';
            is_validate = false;
            this.validateMap.money.status = 'error';
          } else {
            this.validateMap.money.status = '';
            this.validateMap.money.help = '';
          }
        } else if (this.form.type == 2) {
          // 有门槛/折扣券
          const no_pass =
            !this.validateDoubleOne(this.form.limit_money) ||
            !this.validateNumber(this.form.threshold_money);
          if (no_pass) {
            this.validateMap.money.help = !this.validateNumber(this.form.threshold_money)
              ? '满减条件金额请输入正整数'
              : !this.validateDoubleOne(this.form.limit_money)
              ? '折扣请输入最多1位小数的正数'
              : /* : this.form.threshold_money>= this.form.limit_money
                ? '减免金额需小于满减条件金额' */
                '请填写满减金额和折扣';

            this.validateMap.money.status = 'error';
            is_validate = false;
          } else {
            this.validateMap.money.status = '';
            this.validateMap.money.help = '';
          }
        }
      } else if (this.form.threshold === 2) {
        // 无门槛
        if (this.form.type == 1) {
          // 无门槛/满减券
          if (!this.validateNumber(this.form.money)) {
            this.validateMap.money.help = '金额请输入正整数';
            this.validateMap.money.status = 'error';
            is_validate = false;
          } else {
            this.validateMap.money.help = '';
            this.validateMap.money.status = '';
          }
        } else if (this.form.type == 2) {
          // 无门槛/折扣券
          if (!this.validateDoubleOne(this.form.money)) {
            this.validateMap.money.help = '请输入最多1位小数的正数';
            this.validateMap.money.status = 'error';
            is_validate = false;
          } else {
            this.validateMap.money.help = '';
            this.validateMap.money.status = '';
          }
        }
      }
      // if (
      //   this.form.type == 1 &&
      //   this.form.threshold == 2 &&
      //   !this.validateNumber(this.form.money)
      // ) {
      //   this.validateMap.money.help = '金额请填写正整数';
      //   this.validateMap.money.status = 'error';
      //   is_validate = false;
      // } else if (validateMoney) {
      //   this.validateMap.money.help = '请填写最多1位小数的正数';
      //   this.validateMap.money.status = 'error';
      //   is_validate = false;
      // } else if (
      //   this.form.type == 2 &&
      //   this.form.threshold === 1 &&
      //   (!this.form.limit_money || !this.form.threshold_money)
      // ) {
      //   this.validateMap.money.help = !this.form.threshold_money ? '请输入满减金额' : '请输入折扣';
      //   this.validateMap.money.status = 'error';
      //   is_validate = false;
      // } else if (this.form.type == 1 && (validateMoney2 || validateMoney3)) {
      //   if (validateMoney2) {
      //     this.validateMap.money.help = '满减折扣请填写最多1位小数的正数';
      //   } else if (validateMoney3) {
      //     this.validateMap.money.help = '满减金额请输入正整数';
      //   }
      //   this.validateMap.money.status = 'error';
      //   is_validate = false;
      // } else {
      //   this.validateMap.money.help = '';
      //   this.validateMap.money.status = '';
      // }
    }
    if (
      field === 'time' &&
      this.form.expire_type !== 1 &&
      !this.validateNumber(this.form.expire_days)
    ) {
      console.log('budui');
      this.validateMap.time.help = '请输入正整数';
      this.validateMap.time.status = 'error';
      is_validate = false;
    } else if (
      field === 'time' &&
      this.form.expire_type === 1 &&
      this.form.end_time_obj <= this.form.start_time_obj
    ) {
      console.log('time');
      this.validateMap.time.help = '开始时间小于结束时间';
      this.validateMap.time.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.time.help = '';
      this.validateMap.time.status = '';
    }
    if (this.form.circulation_limit == 1 && field === 'circulation_number') {
      if (this.form.circulation_number == undefined) {
        this.validateMap.stock.help = '请输入正整数';
        this.validateMap.stock.status = 'error';
        is_validate = false;
      } else if (this.form.circulation_number > this.form.circulated_number) {
        this.validateMap.stock.help = '请确认发放总量大于已发放数量';
        this.validateMap.stock.status = 'error';
        is_validate = false;
      } else {
        this.validateMap.stock.help = '';
        this.validateMap.stock.status = '';
      }
    }
    if (
      this.form.circulation_limit == 1 &&
      field === 'circulation_number' &&
      (this.form.circulation_number == undefined ||
        !this.validateNumber(this.form.circulation_number))
    ) {
      this.validateMap.stock.help = '请输入正整数';
      this.validateMap.stock.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.stock.help = '';
      this.validateMap.stock.status = '';
    }
    if (
      field == 'product_data' &&
      this.form.range === 2 &&
      this.form.product_data.data.length <= 0
    ) {
      this.validateMap.product_data.help = '请选择商品';
      this.validateMap.product_data.status = 'error';
      is_validate = false;
      console.log('product');
    } else {
      this.validateMap.product_data.help = '';
      this.validateMap.product_data.status = '';
    }
    if (field == 'class_data' && this.form.range === 3 && this.form.class_data.data.length <= 0) {
      this.validateMap.class_data.help = '请选择分类';
      this.validateMap.class_data.status = 'error';
      is_validate = false;
      console.log('class');
    } else {
      this.validateMap.class_data.help = '';
      this.validateMap.class_data.status = '';
    }
    if (
      field === 'personal_limit_number' &&
      this.form.personal_limit == 2 &&
      !this.validateNumber(this.form.personal_limit_number)
    ) {
      this.validateMap.personal_limit_number.help = '请输入正整数';
      this.validateMap.personal_limit_number.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.personal_limit_number.help = '';
      this.validateMap.personal_limit_number.status = '';
    }
    if (
      this.form.type === 2 &&
      field === 'concessions_limit_money' &&
      this.form.concessions_limit === 1 &&
      !this.validateNumber(this.form.concessions_limit_money)
    ) {
      this.validateMap.concessions_limit_money.help = '请输入正整数';
      this.validateMap.concessions_limit_money.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.concessions_limit_money.help = '';
      this.validateMap.concessions_limit_money.status = '';
    }
    if (
      field === 'shown_place' &&
      (this.form.grant_type == 1 || this.form.grant_type == 4) &&
      this.form.shown_place.length <= 0
    ) {
      this.validateMap.shown_place.help = '请勾选显示位置';
      this.validateMap.shown_place.status = 'error';
      is_validate = false;
    } else {
      this.validateMap.shown_place.help = '';
      this.validateMap.shown_place.status = '';
    }
    return is_validate;
  }

  // 关闭分类模态窗
  closeCategoryModal() {
    this.is_show_category_modal = false;
    this.select_category = [];
    this.select_sub_category = [];
    this.sub_category = [];
  }
  // 关闭商品模态窗
  @changeLoading(['is_loading'])
  async closeProductModal() {
    this.is_show_product_modal = false;
    this.modal_form_value = {
      id: '',
      name: '',
      status: 0,
      category: [],
    };
    await this.getWareList();
  }

  // 确认添加分类
  confirmCategory() {
    if (!this.select_category || (!this.select_sub_category && this.select_sub_category !== 0)) {
      this.$message.error('请选择分类');
      return;
    }
    let obj;
    if (this.select_sub_category) {
      this.category.forEach((item: any) => {
        item.children.forEach((item2: any) => {
          if (item2.id === this.select_sub_category) {
            obj = {
              id: item2.id,
              category: '二级分类',
              name: item2.name,
            };
          }
        });
      });
    } else {
      this.category.forEach((item: any) => {
        if (item.id === this.select_category) {
          obj = {
            id: item.id,
            category: '一级分类',
            name: item.name,
          };
        }
      });
    }
    const arr = this.form.class_data.data.map((item: any) => item.id);
    if (arr.indexOf((obj as any).id) !== -1) {
      this.$message.error('该分类已存在，请重新选择');
      return;
    } else {
      this.form.class_data.data.push(obj);
    }
    this.closeCategoryModal();
  }

  // 确认添加商品
  @changeLoading(['is_loading'])
  async confirmProduct() {
    const selected_id_arr = this.form.product_data.data.map((item: any) => item.id);
    this.selectedRows.forEach((item: any) => {
      if (this.selectedRowKeys.includes(item.id) && !selected_id_arr.includes(item.id)) {
        this.form.product_data.data.push(item);
      }
    });
    await this.closeProductModal();
    this.$forceUpdate();
  }

  // 显示商品选择模态窗
  showProductModal() {
    this.is_show_product_modal = true;
    this.selectedRowKeys = this.form.product_data.data.map((item: any) => item.id);
  }

  // 切换有效期类型时清空值
  changeExpireType() {
    this.form.start_time_obj = null;
    this.form.end_time_obj = null;
    this.form.expire_days = '';
  }

  // 切换优惠内容时清空输入框值
  changeThresholdType() {
    this.form.money = '';
    this.form.limit_money = '';
    this.form.threshold_money = '';
  }
  // 勾选模态窗中的商品
  selectedRows: any = [];
  onSelectChange(selectedRowKeys: any, selectedRows: any) {
    //selectedRowKeys=[]
    this.selectedRowKeys = selectedRowKeys;
    selectedRows.forEach((item: any) => {
      if (!this.selectedRows.map((item: any) => item.id).includes(item.id)) {
        this.selectedRows.push(item);
      }
    });
  }
  // 切换一级分类
  changeCategory() {
    this.category.forEach((item: any) => {
      if (item.id === this.select_category) {
        this.sub_category = JSON.parse(JSON.stringify(item.children));
      }
    });
    this.sub_category.unshift({
      id: 0,
      name: '全部',
    });
  }

  paginationOptions(data: any) {
    return {
      total: data.length,
      pageSize: 5,
      showTotal: () => `共${data.length}条，每页5条`,
      current: this.page,
    };
  }

  paginationOption(data: RemotePagination<BannerTableData>) {
    console.log(data.current);

    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 搜索商品
  // @changeLoading(['is_loading'])
  async searchWare(page: number = 1) {
    await this.getWareList({
      current: page,
      limit: 5,
      product_id: this.modal_form_value.id,
      product_name: this.modal_form_value.name,
      is_show: this.modal_form_value.status,
      category_id: this.modal_form_value.category[this.modal_form_value.category.length - 1],
    });
  }

  // 切换商品模态窗分页
  // @changeLoading(['is_loading'])
  onTableChange(data: any) {
    this.searchWare(data.current);
  }

  handleChange(data: any) {
    console.log(data);
    this.page = data.current;
    this.paginationOptions(this.form.class_data.data);
  }

  // 点击分类删除按钮
  cliclDeleteBtn(item: any) {
    this.$confirm({
      title: '确认删除？',
      onOk: () => {
        this.form.class_data.data.splice(
          _.findIndex(this.form.class_data.data, (category: any) => category.id === item.id),
          1
        );
      },
      icon: 'null',
    });
  }

  // 点击商品删除按钮
  deleteProduct(item: any) {
    this.$confirm({
      title: '确认删除？',
      onOk: () => {
        this.form.product_data.data.splice(
          _.findIndex(this.form.product_data.data, (category: any) => category.id === item.id),
          1
        );
      },
      icon: 'null',
    });
  }

  // 获取商品列表
  @changeLoading(['is_loading'])
  async getWareList(query_params?: any) {
    const res = await getWareList(query_params);
    this.ware_list = initWareList(res.data);
  }

  // 获取优惠券详情
  @changeLoading(['is_loading'])
  async getCouponDetail() {
    if (this.is_edit) {
      const res = await getCouponDetail(+this.$route.query.id);
      if (res.status !== 200) {
        this.$message.error((res as any).message);
        return;
      }

      this.form = initForm(res.data);
      console.log(this.form);
      // <---处理发放总量相关
      this.form['rest'] = res.data.stock;
      this.form['issued'] =
        res.data.grant_type == 1
          ? res.data.received_number
          : this.form.grant_type == 2
          ? res.data.code_count
          : 0;

      this.form['stock'] = this.form.issued + this.form.rest;
      // --->
      this.form.start_time_obj = moment(res.data.start_time * 1000);
      this.form.end_time_obj = moment(res.data.end_time * 1000);
      if (res.data.range === 2) {
        res.data.coupon_product.forEach(async (item: any) => {
          const res = await getWareList({
            current: 1,
            product_id: item.product_id,
          });
          if (res.status === 200) {
            this.form.product_data.data.push(res.data.detail[0]);
          }
        });
      }
      if (res.data.range === 3) {
        const category_id_arr = res.data.coupon_category.map((item: any) => item.category_id);
        const _data = await queryWareCategory();

        this.form.class_data.data = _data
          .reduce<any[]>((prev, cur: any) => {
            prev.push(cur);
            if (cur.children && cur.children.length > 0) {
              prev.push(...cur.children);
            }
            return prev;
          }, [])
          .filter(item => category_id_arr.some((val: any) => val == item.id))
          .map(item => ({ id: item.id, category: item.level_text, name: item.name }));
      }
    }
  }

  @changeLoading(['is_loading'])
  async fetchWareCategory() {
    const _data = await queryWareCategory();
    this.category = _data.map((item: any) => {
      return {
        ...item,
        operation: '新增二级分类',
      };
    });
  }
  @changeLoading(['is_loading'])
  async created() {
    this.form.range = storage.getItem('coupon_type').range;
    this.form.type = storage.getItem('coupon_type').type;
    this.form.grant_type = storage.getItem('coupon_type').grant_type;
    this.form.user_type = storage.getItem('coupon_type').user_type;
    this.form.points_exchange = storage.getItem('coupon_type').points_exchange;
    this.form.points = storage.getItem('coupon_type').points;
    // this.is_edit = Boolean(this.$route.query.id);
    await this.fetchWareCategory();
    await this.getWareList();
    await this.getCouponDetail();
    if (!this.is_edit && this.form.range === 1) {
      this.form.shown_place = [];
    }
    //如果是系统发放的优惠卷，时间框隐藏
    if (this.form.grant_type == 3) {
      this.form.expire_type = 2;
    }
  }
  range(start: any, end: any) {
    const result: any = [];
    for (let i = start; i >= end; i--) {
      result.push(i);
    }
    return result;
  }
  //时间验证
  startTime: any = '';
  disabledDate(current: any) {
    // Can not select days before today and today
    //return current && current <= moment().endOf('day');
    const nowDate = new Date();
    this.startTime =
      nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    //console.log(this.startTime)
    return current < moment(this.startTime).add(0, 'day');
  }

  disabledDateTime(dates: any) {
    const hours = moment().hours();
    const minutes = moment().minutes();
    const seconds = moment().seconds();
    if (dates && moment(dates).date() === moment().date()) {
      return {
        disabledHours: () => this.range(hours - 1, 0),
        disabledMinutes: () => this.range(minutes - 1, 0),
        disabledSeconds: () => this.range(seconds - 1, 0),
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }
}
